@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  button {
    @apply border py-2 px-3 rounded-full
  }
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
  color:black !important;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
  color:black !important;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero {
    text-align: center;
    padding: 20px;
  }

  .plan, .introduction, .why-us, .machine-learning {
    padding: 15px;
  }

  .image-slider {
    width: 100%;
  }

  .footer {
    text-align: center;
  }
}